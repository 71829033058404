/** @jsxImportSource @emotion/react */
import { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useNavigate } from 'react-router-dom'

import ButtonModal from 'components/atoms/ButtonModal'
import Button from 'components/atoms/Button'
import { useInitiateTsFulfillmentMutation } from 'store/services/dmeOrder'
import { Order } from 'store/types'
import { OneShipSelection } from 'components/dme/orderEdit/views/oneShip/OneShipSelection'

interface IProps {
  open: boolean
  onClose: () => void
  order: Order
}

const OneShipModal = ({ open, onClose, order }: IProps) => {
  const navigate = useNavigate()
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [initiateTsFulfillment] = useInitiateTsFulfillmentMutation()
  const orderId = Number(order?.id)

  const handleOnComplete = () => {
    setShowConfirmation(true)
  }

  return (
    <Modal centered show={open} onHide={onClose} size="lg">
      <Modal.Header>
        <Modal.Title>
          {showConfirmation ? 'Success!' : `OneShip Info for Order #${orderId}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showConfirmation ? (
          <div role="alert">
            <p>
              The order has been successfully submitted to us for OneShip. We will deliver a device
              to the patient and handle the AOB and device data processing for you. <br />
              <br />
              You will be able to see updates in the OneShip tab of this order.
              <br />
              <br />
              <div
                className="p-2"
                css={{ borderRadius: '5px', border: '1px solid #ccc', background: '#c6e3fa80' }}>
                Effective January 1, 2025, due to insufficient testing data, all OneShip orders will
                be required to undergo testing for two nights to ensure sufficient data is collected
                for the patient. If both nights of collected data are valid, both reports will be
                sent to the referring physician.
              </div>
            </p>
          </div>
        ) : (
          <OneShipSelection
            order={order}
            initiateTsFulfillment={(params) => initiateTsFulfillment(params).unwrap()}
            onClose={handleOnComplete}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        {showConfirmation ? (
          <ButtonModal
            label="OK"
            className="btn-default"
            data_dismiss="modal"
            onClick={() => {
              onClose()
              navigate('/dme/order/hub/?testType=POX&bucket=OPEN')
            }}
          />
        ) : (
          <>
            <ButtonModal
              label="Cancel"
              className="btn-default"
              data_dismiss="modal"
              onClick={onClose}
            />
            <Button
              label="Save"
              type="submit"
              id="btn-ts"
              className="ml-2"
              // data-dismiss="modal"
              disabled={false}
              form="NightOwl"
            />
          </>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default OneShipModal
