/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled'
import Button from 'components/atoms/Button'
import ButtonSave from 'components/atoms/ButtonSave'
import Dropdown from 'components/atoms/Dropdown'
import Input from 'components/atoms/Input'
import Pagination from 'components/atoms/Pagination'
import { getSortSettings } from 'helpers/generalHelper'

import * as generalHelper from 'helpers/generalHelper'
import { useEffect, useState } from 'react'
import { useSortBy, useTable } from 'react-table'
import { KeyVal } from 'store/types'

const StyledRow = styled.tr`
  :hover {
    background: rgba(99, 99, 99, 0.15);
  }
`

type TableData = {
  items: any[];
  currentPage: number;
  totalItems: number;
}
interface IProps {
  customTableLayout?: any;
  data: any[] | TableData;
  entityName?: string;
  highlightRowId?: number;
  onExport?: () => void;
  onRowClick?: (row: any) => void;
  pageSize: number;
  searchable?: boolean;
  searchFilter?: string;
  searchPlaceholder?: string;
  select?: boolean;
  setCurrentPage: (v: number) => void;
  setPageSize: (v: number) => void;
  setSearchFilter?: (v: string) => void;
  setSortOptions?: (v: KeyVal) => void;
  sortable?: boolean;
  status: boolean;
  tableColumns: any[];
  tableDataEmptyText?: string;
  tableDataErrorText?: string;
  tableKey: string;
  useCustomSortHandler?: boolean;
}

/**
 * Renders ReactTable's child elements and sets proper event handling
 *
 * @param Object props
 * @returns JSX.Element
 */
const DataTable = ({
  customTableLayout,
  data,
  entityName = 'orders',
  highlightRowId,
  onExport,
  onRowClick = () => {},
  pageSize,
  searchable = true,
  searchFilter,
  searchPlaceholder = 'First Name, Last Name, Order ID, DOB',
  select,
  setCurrentPage,
  setPageSize,
  setSearchFilter,
  setSortOptions,
  sortable = true,
  status,
  tableColumns,
  tableDataEmptyText,
  tableDataErrorText,
  tableKey,
  useCustomSortHandler,
}: IProps) => {
  const { items, currentPage, totalItems } = data || {}
  const [tableState, setTableState] = useState({})

  const ReactTable = useTable(
    {
      columns: tableColumns,
      data: items || [],
      manualSortBy: true,
    },
    useSortBy,
  )

  // Set listener for sortBy Events from Our Table
  useEffect(() => {
    const sortBy = tableState.sortBy ?? undefined

    if (setSortOptions) {
      if (sortBy) {
        setSortOptions(sortBy)
      } else if (sortBy === undefined) {
        setSortOptions()
      }
    }
  }, [tableState])

  const setMergedTableState = (val) => setTableState((prevState) => ({ ...prevState, ...val }))

  if (customTableLayout) {
    return customTableLayout
  } else {
    return (
      <TableLayout
        ReactTable={ReactTable}
        currentPage={Number(currentPage)}
        customSortHandler={useCustomSortHandler ? setSortOptions : undefined}
        entityName={entityName}
        filterState={setSearchFilter}
        filterValue={searchFilter}
        highlightRowId={highlightRowId}
        onExport={onExport}
        onRowClick={onRowClick}
        pageSize={pageSize}
        searchable={searchable}
        searchPlaceholder={searchPlaceholder}
        select={select}
        setCurrentPage={setCurrentPage}
        setPageSize={setPageSize}
        tableColumns={tableColumns}
        tableDataEmptyText={tableDataEmptyText}
        tableDataErrorText={tableDataErrorText}
        tableDataState={(Array.isArray(data) ? !!data?.length : !!data?.items?.length) ? false : status}
        tableKey={tableKey}
        tableLayout={customTableLayout}
        tableState={setMergedTableState}
        tableTotalRows={totalItems || 1}
      />
    )
  }
}

const TableLayout = (props) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setSortBy,
  } = props.ReactTable;
  const {
    currentPage,
    customSortHandler,
    filterState,
    highlightRowId,
    onExport,
    onRowClick,
    pageSize,
    searchable,
    searchPlaceholder,
    setCurrentPage,
    setPageSize,
    tableDataEmptyText = `No ${props.entityName} found.`,
    tableDataErrorText = `Error getting ${props.entityName}.`,
    tableKey,
  } = props;
  const [search, setSearch] = useState('');
  const [currentTableKey, setCurrentTableKey] = useState('');

  useEffect(() => {
    const tableChanged = tableKey !== currentTableKey

    const sortBy = state.sortBy[0]

    if (tableChanged) {
      if (!!currentTableKey && sortBy) {
        setSortBy([])
      }

      setCurrentTableKey(tableKey)
    }

    if (customSortHandler) {
      customSortHandler(sortBy)
    } else {
      props.tableState({ sortBy })
    }
  }, [state])

  useEffect(() => {
    props.tableState({
      skip: currentPage === 1 ? 0 : (currentPage - 1) * pageSize,
      limit: pageSize,
    })
  }, [currentPage])

  useEffect(() => {
    const sortSettings = getSortSettings(tableKey)

    if (sortSettings) {
      const { field, order } = sortSettings

      const header = headerGroups[0].headers.find((header: any) => header.id === field)

      if (header) {
        header.toggleSortBy(order === 'desc' || order === 'DESC')
      }
    }
  }, [currentTableKey])

  if (props.tableLayout) {
    return props.tableLayout
  } else {
    return (
      <>
        <table {...getTableProps({ className: 'table' })} id="data-table">
          {!!filterState && (
            <thead css={{ zIndex: 1000 }} className="table_filters">
              <tr>
                <td colSpan={headerGroups?.[0]?.headers?.length ?? 1} style={{ border: 'none' }}>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="row p1-2">
                        {!!searchable && (
                            <>
                              <div className="col-sm-3">
                                <Input
                                    value={search || ''}
                                    label="Search"
                                    placeholder={searchPlaceholder}
                                    name="search"
                                    parentDiv={null}
                                    handleChange={(e) => setSearch(e.target.value)}
                                    onKeyPress={(e) => {
                                      if (e.key === 'Enter') {
                                        filterState(search)
                                      }
                                    }}/>
                              </div>
                              <div className="col-sm-4 col-md-4">
                                <ButtonSave
                                    className="btn btn-primary mr-3"
                                    type="button"
                                    label="Search"
                                    onClick={(event) => {
                                      filterState(search)
                                    }}/>
                                <Button
                                    label="Clear"
                                    className="btn btn btn-default"
                                    onClick={() => {
                                      filterState('')
                                      setSearch('')
                                    }}/>
                              </div>
                            </>
                        )}
                        <div className="d-flex justify-content-end" style={{ flex: 1 }}>
                          {onExport && (
                            <ButtonSave
                              className="btn btn-primary mr-3"
                              type="button"
                              label="Export CSV"
                              onClick={onExport}
                            />
                          )}
                          <Dropdown
                            additionalText="Records"
                            buttonTypeClass="btn-outline-primary"
                            initialValue={pageSize}
                            onClick={(v) => {
                              setCurrentPage(0)
                              setPageSize(Number(v))
                            }}
                            options={[
                              { label: '12'  },
                              { label: '20'  },
                              { label: '50'  },
                              { label: '100' },
                              { label: '200' },
                            ]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </thead>
          )}
          {
          // Note that the following z-index must be lower than the table_filters z-index above.
          // Otherwise, when you go to change the page size, for example, the dropdown floats
          // behind the table headers because that thead is set to `position: sticky`.
          }
          <thead css={{ zIndex: 100 }}>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup) => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column) => (
                      // Apply the header cell props
                      <th
                        {...column.getHeaderProps({
                          ...column.getSortByToggleProps(),
                          onClick: async (e: MouseEvent) => {
                            const func = column.getSortByToggleProps().onClick

                            if (!func) {
                              return
                            }

                            const result = await func(e)

                            const isSorted = column.isSorted
                            const desc = !!column.isSortedDesc

                            if (!isSorted) {
                              generalHelper.deleteSortSettings(tableKey)
                            } else {
                              generalHelper.setSortSettings(tableKey, {
                                field: column.id,
                                order: desc ? 'desc' : 'asc',
                              })
                            }

                            return result
                          },
                          className: `table-col ${column.className}`,
                        })}>
                        {
                          // Render the header
                          column.render('Header')
                        }
                        <span>{column.isSorted ? (column.isSortedDesc ? ' ⇣' : ' ⇡') : ''}</span>
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          {props.tableDataState && (
            <tbody {...getTableBodyProps()}>
              <tr>
                <td colSpan="100%">
                  <div className="row border rounded m-2 py-3 pl-2 justify-content-center font-italic textGray font-weight-bold">
                    {generalHelper.t('Loading Data...')}
                  </div>
                </td>
              </tr>
            </tbody>
          )}
          {!props.tableDataState && rows.length > 0 ? (
            // Loop over the table rows
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                // Prepare the row for display
                prepareRow(row)
                return (
                  // Apply the row props
                  <StyledRow
                    {...row.getRowProps()}
                    onClick={() => !props.select && onRowClick(row)}
                    css={{
                      // cursor: props.select ? 'default' : 'pointer',
                      backgroundColor:
                        highlightRowId && highlightRowId == row.original.id ? '#f0f0f0' : 'white',
                    }}>
                    {
                      // Loop over the rows cells
                      row.cells.map((cell) => {
                        // Apply the cell props
                        return (
                          <td {...cell.getCellProps()}>
                            <div className="container pl-0">
                              {
                                // Render the cell contents
                                cell.render('Cell')
                              }
                            </div>
                          </td>
                        )
                      })
                    }
                  </StyledRow>
                )
              })}
            </tbody>
          ) : (
            <tbody {...getTableBodyProps()}>
              <tr>
                <td colSpan="100%">
                  {props.tableDataState === false && rows.length == 0 && (
                    <>
                      <div className="row border rounded m-2 py-3 pl-2 justify-content-center font-italic textGray font-weight-bold">
                        {generalHelper.t(tableDataEmptyText)}
                      </div>
                    </>
                  )}
                  {props.tableDataState === 'error' && rows.length == 0 && (
                    <>
                      <div className="row border rounded m-2 py-3 pl-2 justify-content-center font-italic textGray font-weight-bold">
                        {generalHelper.t(tableDataErrorText)}
                      </div>
                    </>
                  )}
                </td>
              </tr>
            </tbody>
          )}

          {!props.tableDataState && (
            <tfoot>
              <tr>
                <th colSpan="100%">
                  <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={props.tableTotalRows}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    onPageChange={setCurrentPage}
                  />
                </th>
              </tr>
            </tfoot>
          )}
        </table>
      </>
    )
  }
}

export default DataTable
