/** @jsxImportSource @emotion/react */
import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { skipToken } from '@reduxjs/toolkit/query/react'
import { capitalize, pick } from 'lodash'
import Button from 'react-bootstrap/Button'
import { Formik, Form, Field } from 'formik'
import { v4 as uuidv4 } from 'uuid'
import Select from 'react-select'

import { useAppSelector } from 'hooks/redux'
import Toast from 'components/atoms/Toast'
import MaskInput from 'components/atoms/MaskInput'
import { phoneNumberMask } from 'helpers/masks'
import { confirm } from 'components/molecules/Confirmation'
import {
  useCreateDmeUserMutation,
  useUpdateDmeUserMutation,
  useDmeDeleteUserMutation,
  useSendDmeResetPasswordEmailMutation,
} from 'store/services/users'
import { useGetDmeOfficesQuery, useGetDmeCompanyQuery } from 'store/services/office'
import { useGetDmeUserQuery } from 'store/services/users'
import { UserType } from 'store/types'
import { NotificationPreferences } from 'components/organisms/user/NotificationPreferences'
import {
  getUserNotificationPrefs,
  getInitialFormValues,
} from 'components/organisms/user/notificationHelpers'
import { DME_ROLES } from 'constants/roles'
import { userValidation } from 'helpers/validate'

interface FormValues {
  firstName: string
  lastName: string
  officePhone: string
  directPhone: string
  email: string
  role: string
  title: string
}

const initialValues = {
  firstName: '',
  lastName: '',
  officePhone: '',
  directPhone: '',
  email: '',
  role: '',
  title: '',
  active: true,
  primaryOfficeAdmin: false,
  assignedOfficesAdmin: false,
  notificationPreferences: {},
}

const UserUpsert = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [createUser] = useCreateDmeUserMutation()
  const [updateUser] = useUpdateDmeUserMutation()
  const [deleteUser] = useDmeDeleteUserMutation()
  const isSsoEnabled = useAppSelector((state) => state.init.isSsoEnabled)
  const userId = useAppSelector((state) => state.init.id)
  const [sendResetPasswordEmail] = useSendDmeResetPasswordEmailMutation()
  // const { data: companyUsers } = useGetDmeUsersQuery({ size: 1000 })
  const [officeValues, setOfficeValues] = useState<any>({
    companyId: 1,
    primaryOfficeId: searchParams.get('officeId'),
    addlOfficeId: '',
  })
  const [addlOffices, setAddlOffices] = useState([])
  const { data: offices } = useGetDmeOfficesQuery()
  const { data: user } = useGetDmeUserQuery(userId ? { id: Number(userId) } : skipToken)
  const { data: companyDetails } = useGetDmeCompanyQuery()
  const validation = useMemo(
    () => userValidation(companyDetails?.companyDomains ?? []),
    [companyDetails],
  )
  const isAdmin = user?.adminOf === 'PRIMARY_OFFICE' || user?.adminOf === 'ASSIGNED_OFFICES'
  // const companyAdmins =
  //   companyUsers?.items?.filter((u) => !!u.adminOf).map((u) => `${u.firstName} ${u.lastName}`) ?? []

  const officesOptions =
    useMemo(
      () =>
        offices?.map((office) => ({
          label: `${office.officeName} (${office.addressCity}, ${office.addressState})`,
          value: Number(office.id),
        })),
      [offices],
    ) || []

  useEffect(() => {
    if (user) setOfficeValues((p) => ({ ...p, companyId: user.company }))
  }, [user])

  useEffect(() => {
    if (user && officesOptions?.length) {
      const addlOffices = officesOptions.filter(
        (o) =>
          (user.offices.includes(o.value) || user.offices.includes(o.value.toString())) &&
          Number(o.value) !== Number(user.primaryOffice),
      )

      setAddlOffices(addlOffices ?? [])

      if (!officeValues.primaryOfficeId) {
        setOfficeValues((p) => ({ ...p, primaryOfficeId: user.primaryOffice }))
      }
    }
  }, [user, officesOptions])

  const formInit = useMemo(() => {
    const initNotifications = getInitialFormValues(user?.notificationPreferences)

    return {
      ...initialValues,
      ...(user && {
        ...pick(user, ['email', 'firstName', 'lastName', 'officePhone', 'directPhone']),
        role: user?.roles?.[0],
        active: user?.active ?? false,
        primaryOfficeId: user?.primaryOffice,
        primaryOfficeAdmin: user?.adminOf === 'PRIMARY_OFFICE',
        assignedOfficesAdmin: user?.adminOf === 'ASSIGNED_OFFICES',
        ...initNotifications,
      }),
    }
  }, [user])

  return (
    <div>
      <div css={{ display: 'flex', justifyContent: 'flex-end' }}>
        {!isSsoEnabled && (
          <Button
            variant="outline-danger mr-5"
            css={{}}
            onClick={() => {
              confirm('', {
                title: 'Are you sure?',
                description: 'This will send a password reset email to this user.',
                yesAction: 'Yes, proceed',
                noAction: 'Cancel',
              })
                .then(async () => {
                  try {
                    await sendResetPasswordEmail({ email: user?.email }).unwrap()
                    Toast({ type: 'success', label: `Password reset email sent.` })
                  } catch (err) {
                    Toast({ type: 'error', label: `Error sending password reset email.` })
                  }
                })
                .catch(() => {})
            }}>
            Reset Password
          </Button>
        )}

        <Button type="submit" form="userUpsert" variant="primary">
          Save Changes
        </Button>
      </div>
      <Formik
        enableReinitialize
        validationSchema={validation}
        initialValues={formInit}
        onSubmit={async (values) => {
          const { role, roles, primaryOfficeAdmin, assignedOfficesAdmin, nickName, ...vals } =
            values
          vals.roles = [role]
          const offices = [
            ...new Set([
              Number(officeValues.primaryOfficeId),
              ...addlOffices.map((o) => Number(o.value)),
            ]),
          ]
          const adminOf = primaryOfficeAdmin
            ? 'PRIMARY_OFFICE'
            : assignedOfficesAdmin
              ? 'ASSIGNED_OFFICES'
              : null

          const [notificationPreferences, rest] = getUserNotificationPrefs(vals)

          const common = {
            userType: UserType.DME,
            offices,
            primaryOffice: Number(officeValues.primaryOfficeId),
            company: Number(officeValues.companyId),
            permissions: [],
            adminOf,
            ...rest,
            notificationPreferences,
          }
          try {
            if (userId) {
              await updateUser({
                id: Number(userId),
                ...common,
              }).unwrap()
              Toast({ type: 'success', label: `User updated!` })
            } else {
              await createUser({
                ...common,
                password: uuidv4(),
              }).unwrap()
              Toast({ type: 'success', label: `User created!` })
            }
          } catch (err) {
            Toast({ type: 'error', label: err.data?.message ?? 'Error saving user' })
          }
        }}>
        {({ values, errors, touched, handleChange, setFieldValue }) => (
          <Form id="userUpsert">
            <div className="card-body row">
              <div className="col-md-6">
                <div className="card card-primary p-2 mb-0">
                  <div
                    className="card-header d-flex"
                    css={{
                      color: 'black !important',
                      backgroundColor: 'transparent !important',
                      justifyContent: 'space-between',
                      '&::after': {
                        display: 'none',
                      },
                    }}>
                    <h3 className="card-title">USER INFORMATION</h3>
                  </div>
                  <div className={`container bg-white mb-3 px-1 p-0`}>
                    <div className={`container pt-3 pb-2`}>
                      <div className="row mb-3">
                        <div className="col-6">
                          <label className="text-gray">
                            Primary Office <span className="text-danger">*</span>
                          </label>
                          <Select
                            isClearable
                            name="primaryOfficeId"
                            value={
                              officesOptions.find(
                                (c) => c?.value == officeValues.primaryOfficeId,
                              ) ?? null
                            }
                            options={officesOptions}
                            onChange={(event) => {
                              setOfficeValues((p) => ({ ...p, primaryOfficeId: event?.value }))
                              setFieldValue('primaryOfficeId', event?.value)
                            }}
                          />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-6 form-group textGray">
                          <label htmlFor="firstName">
                            First Name
                            <span className="text-danger">*</span>
                          </label>
                          <Field
                            type="text"
                            name="firstName"
                            className="form-control"
                            placeholder="First Name"
                            value={values.firstName}
                            onChange={handleChange}
                            autoComplete="one-time-code"
                          />
                          {touched.firstName && errors.firstName && (
                            <div className="text-danger">{errors.firstName}</div>
                          )}
                        </div>
                        <div className="col-6 form-group textGray">
                          <label htmlFor="lastName">
                            Last Name
                            <span className="text-danger">*</span>
                          </label>
                          <Field
                            type="text"
                            name="lastName"
                            className="form-control"
                            placeholder="Last Name"
                            value={values.lastName}
                            onChange={handleChange}
                            autoComplete="one-time-code"
                          />
                          {touched.lastName && errors.lastName && (
                            <div className="text-danger">{errors.lastName}</div>
                          )}
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-6 form-group textGray">
                          <label htmlFor="officePhone">Office Number</label>
                          <MaskInput
                            value={values.officePhone}
                            onChange={handleChange}
                            name="officePhone"
                            mask={phoneNumberMask}
                            placeholder="(###) ###-####"
                            autoComplete="off"
                          />
                          {touched.officePhone && errors.officePhone && (
                            <div className="text-danger">{errors.officePhone}</div>
                          )}
                        </div>
                        <div className="col-6 form-group textGray">
                          <label htmlFor="directPhone">Mobile Number</label>
                          <MaskInput
                            value={values.directPhone}
                            onChange={handleChange}
                            name="directPhone"
                            mask={phoneNumberMask}
                            placeholder="(###) ###-####"
                            autoComplete="off"
                          />

                          {touched.directPhone && errors.directPhone && (
                            <div className="text-danger">{errors.directPhone}</div>
                          )}
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-6 form-group textGray">
                          <label htmlFor="email">Email</label>
                          <span className="text-danger">*</span>
                          <Field
                            disabled
                            type="text"
                            name="email"
                            className="form-control"
                            placeholder="Email"
                            value={values.email}
                            onChange={(e) => {
                              e.target.value = e.target.value.toLowerCase()
                              handleChange(e)
                            }}
                            autoComplete="one-time-code"
                          />
                          {touched.email && errors.email && (
                            <div className="text-danger">{errors.email}</div>
                          )}
                        </div>
                        <div className="col-6 form-group textGray">
                          <label htmlFor="role">Role</label>
                          <span className="text-danger">*</span>
                          <Field
                            disabled={!isAdmin}
                            className="form-control"
                            as="select"
                            name="role"
                            placeholder="Role">
                            <option value="">Select Role</option>
                            {DME_ROLES.map((s) => (
                              <option key={s} value={s}>
                                {capitalize(s.replace('_', ' '))}
                              </option>
                            ))}
                          </Field>
                          {touched.role && errors.role && (
                            <div className="text-danger">Required</div>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-sm-12 pt-3 pr-0 border-top">
                          <div className="col-sm-12 d-flex justify-content-end">
                            <Button variant="primary">Save Changes</Button>
                          </div>
                        </div>*/}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card card-primary p-2">
                  <div
                    className="card-header"
                    css={{
                      color: 'black !important',
                      backgroundColor: 'transparent !important',
                    }}>
                    <h3 className="card-title">ADDITIONAL OFFICES</h3>
                  </div>
                  <div className="card-body">
                    <div className="row mb-3">
                      <div className="col-12">
                        <div className="d-flex align-items-end">
                          <div css={{ flex: 1, marginRight: 8 }}>
                            <label className="text-gray">Additional Offices</label>
                            <Select
                              isClearable
                              name="addlOfficeId"
                              value={
                                officesOptions.find((c) => c?.value == officeValues.addlOfficeId) ??
                                null
                              }
                              options={officesOptions.filter(
                                (o) =>
                                  o.value !== officeValues.primaryOfficeId &&
                                  !addlOffices.find((a) => a.value == o.value),
                              )}
                              onChange={(event) => {
                                setOfficeValues((p) => ({ ...p, addlOfficeId: event?.value }))
                              }}
                            />
                          </div>
                          <Button
                            variant="primary"
                            disabled={!officeValues.addlOfficeId}
                            onClick={() => {
                              setAddlOffices((p) => [
                                ...p,
                                officesOptions?.find((o) => o.value == officeValues.addlOfficeId),
                              ])
                              setOfficeValues((p) => ({ ...p, addlOfficeId: null }))
                            }}>
                            Add Office
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div
                      className="row flex-row flex-nowrap justify-content-between align-items-center p-2 my-2"
                      style={{
                        color: '#6D757E',
                        borderBottom: '1px solid #ced4da',
                      }}>
                      <div style={{ width: '100px' }}>Office ID</div>
                      <div style={{ width: '100%' }}>Office</div>
                      <div className="flex-shrink-0 pr-3" style={{ width: 100, textAlign: 'end' }}>
                        Action
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-12" css={{ maxHeight: '219px', overflowY: 'auto' }}>
                        {!addlOffices?.length && (
                          <div className="row my-1">
                            <div className="col-sm-12 p-0">
                              <div className="list-group text-secondary">
                                <div className="list-group-item list-group-item-action flex-column align-items-center">
                                  <div className="d-flex py-1 justify-content-center align-items-center">
                                    <span>No Additional Offices Selected</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {addlOffices?.map((office) => (
                          <div className="row my-1" key={office?.value}>
                            <div className="col-sm-12 p-0">
                              <div className="list-group text-secondary">
                                <div className="list-group-item list-group-item-action flex-column align-items-start">
                                  <div className="d-flex py-1 justify-content-between align-items-center">
                                    <span css={{ width: '70px' }}>{office?.value}</span>
                                    <span css={{ flex: 1 }}>{office?.label}</span>
                                    <Button
                                      variant="primary"
                                      className="ml-2"
                                      css={{ minWidth: '60px' }}
                                      size="sm"
                                      onClick={() =>
                                        confirm('office')
                                          .then(() => {
                                            setAddlOffices((p) =>
                                              p.filter((o) => o.value !== office.value),
                                            )
                                          })
                                          .catch(() => {})
                                      }>
                                      Remove
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NotificationPreferences />
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default UserUpsert
