import { Field, FieldProps } from 'formik'
import { SyntheticEvent, useState, useRef } from 'react'
import MaskedInput from 'react-text-mask'

interface IProps {
  value: string
  name: string
  mask: (string | RegExp)[]
  onChange: (e: SyntheticEvent) => void
  placeholder?: string
  autoComplete?: string
  guide?: boolean
  disabled?: boolean
}

const InputMask = ({
  value,
  name,
  mask,
  guide,
  onChange,
  placeholder,
  autoComplete,
  disabled,
}: IProps) => {
  const inputRef = useRef(null)

  const handleChange = (e) => {
    const input = e.target
    const start = input.selectionStart
    // Convert to uppercase while keeping cursor position intact
    const newValue = e.target.value.toUpperCase()
    e.target.value = newValue
    onChange(e)
    // Restore cursor position
    setTimeout(() => {
      inputRef.current.setSelectionRange(start, start)
    }, 0)
  }

  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => (
        <>
          <MaskedInput
            render={(ref, props) => (
              <input
                {...props}
                ref={(node) => {
                  ref(node)
                  inputRef.current = node
                }}
              />
            )}
            disabled={disabled}
            type="text"
            {...field}
            value={value}
            mask={mask}
            guide={guide}
            className="form-control"
            onChange={handleChange}
            placeholder={placeholder}
            autoComplete={autoComplete}
          />
          {form.errors[field.name] && <div className="text-danger">{form.errors[field.name]}</div>}
        </>
      )}
    </Field>
  )
}

export default InputMask
