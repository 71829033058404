import Menu from './menu/Menu'
import Index from './index/Index'
import Footer from './footer/Footer'

import Companies from './company/Companies'
import CompanyUpsert from './company/CompanyUpsert'
import OfficeUpsert from './office/OfficeUpsert'
import Fax from './fax/Fax'
import Order from './order/Order'
import OrderEdit from './orderEdit/OrderEdit'
import OrderNightOwlEdit from './orderEdit/NightOwlEdit'
import SendFax from './fax/SendFax'
import IncomingFax from './fax/IncomingFax'
import Device from './device/Device'
import DeviceUpsert from './device/DeviceUpsert'
import File from './file/File'
import FileUpsert from './file/FileUpsert'
import FileType from './fileType/FileType'
import FileTypeUpsert from './fileType/FileTypeUpsert'
import DeviceType from './deviceType/DeviceType'
import DeviceTypeUpsert from './deviceType/DeviceTypeUpsert'
import DeviceCategory from './deviceCategory/DeviceCategory'
import DeviceCategoryUpsert from './deviceCategory/DeviceCategoryUpsert'
import DeviceTypeLink from './deviceTypeLink/DeviceTypeLink'
import DeviceTypeLinkUpsert from './deviceTypeLink/DeviceTypeLinkUpsert'
import PhysicianGroupsDirectory from './physicianGroups/PhysicianGroupsDirectory'
import PhysicianGroupUpsert from './physicianGroups/PhysicianGroupUpsert'
import PhysicianGroupUsersDirectory from './physicianGroups/PhysicianGroupUsersDirectory'
import PhysicianGroupUserUpsert from './physicianGroups/PhysicianGroupUserUpsert'
import Status from './status/Status'
import StatusUpsert from './status/StatusUpsert'
import Payer from './payer/Payer'
import DmePayer from './payer/DmePayer'
import OrderDiagnostic from './orderDiagnostic'
import Directory from './patient/Directory'
import PatientDetails from './patient/PatientDetails'
import NewExistingPatientOrder from 'components/dme/order/NewExistingPatientOrder'
import LabUsersList from 'components/lab/user/Directory'
import LabUserUpsert from 'components/lab/user/UserUpsert'
import DmeUserDirectory from './office/UserDirectory'
import DmeUserUpsert from './office/UserUpsert'
import Stats from './managementReports/Stats'
import Analytics from './managementReports/Analytics'
import BulletinDirectory from './bulletins/BulletinDirectory'
import BulletinUpsert from './bulletins/BulletinUpsert'

import NewPatient from 'components/lab/patient/NewPatient'
import NewOrder from 'components/lab/patient/NewPatientOrder'

import routes from './constants/routes'
import './css/extend.css'
import './css/extend.scss'
import { Routes, Route } from 'react-router-dom'
import DeviceImport from "./deviceImport/DeviceImportTable";

const DashboardLab = () => {
  return (
    <div className="wrapper">
      {/* <Nav /> */}
      <Menu />
      <div className="content-wrapper" style={{ marginLeft: 230 }}>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path={`${routes.company.root}`} element={<Companies />} />
          <Route path={`${routes.company.root}${routes.upsert}`} element={<CompanyUpsert />} />
          <Route path={`${routes.company.root}${routes.upsert}:companyId`} element={<CompanyUpsert />} />
          <Route path={`${routes.office.root}${routes.upsert}`} element={<OfficeUpsert />} />
          <Route path={`${routes.office.root}${routes.upsert}:officeId`} element={<OfficeUpsert />} />
          <Route path={`${routes.fax.root}`} element={<Fax />} />
          <Route path={`${routes.fax.root}${routes.fax.incoming}`} element={<IncomingFax />} />
          <Route path={`${routes.fax.root}${routes.fax.send}`} element={<SendFax />} />
          <Route path={`${routes.deviceType.root}`} element={<DeviceType />} />
          <Route path={`${routes.deviceType.root}${routes.upsert}`} element={<DeviceTypeUpsert />} />
          <Route path={`${routes.deviceType.root}${routes.upsert}:id`} element={<DeviceTypeUpsert />} />
          <Route path={`${routes.deviceCategory.root}`} element={<DeviceCategory />} />
          <Route path={`${routes.deviceCategory.root}${routes.upsert}`} element={<DeviceCategoryUpsert />} />
          <Route path={`${routes.deviceCategory.root}${routes.upsert}:id`} element={<DeviceCategoryUpsert />} />
          <Route path={`${routes.deviceTypeLink.root}`} element={<DeviceTypeLink />} />
          <Route path={`${routes.deviceTypeLink.root}${routes.upsert}`} element={<DeviceTypeLinkUpsert />} />
          <Route path={`${routes.deviceTypeLink.root}${routes.upsert}:id`} element={<DeviceTypeLinkUpsert />} />
          <Route path={`${routes.device.root}`} element={<Device />} />
          <Route path={`${routes.device.root}${routes.upsert}`} element={<DeviceUpsert />} />
          <Route path={`${routes.device.root}${routes.upsert}:id`} element={<DeviceUpsert />} />
          <Route path={`${routes.status.root}`} element={<Status />} />
          <Route path={`${routes.status.root}${routes.upsert}`} element={<StatusUpsert />} />
          <Route path={`${routes.status.root}${routes.upsert}:id`} element={<StatusUpsert />} />
          <Route path={`${routes.deviceImport.root}`} element={<DeviceImport />} />
          <Route path={`${routes.payer.root}`} element={<Payer />} />
          <Route path={`${routes.dmePayer.root}`} element={<DmePayer />} />
          <Route path={`${routes.file.root}`} element={<File />} />
          <Route path={`${routes.file.root}${routes.upsert}`} element={<FileUpsert />} />
          <Route path={`${routes.file.root}${routes.upsert}:id`} element={<FileUpsert />} />
          <Route path={`${routes.fileType.root}`} element={<FileType />} />
          <Route path={`${routes.fileType.root}${routes.upsert}`} element={<FileTypeUpsert />} />
          <Route path={`${routes.fileType.root}${routes.upsert}:id`} element={<FileTypeUpsert />} />
          <Route path={`${routes.order.root}`} element={<Order />} />
          <Route path={`${routes.order.edit}:orderId`} element={<OrderEdit />} />
          <Route path={`${routes.order.edit}${routes.order.nightOwl}:orderId`} element={<OrderNightOwlEdit />} />
          <Route path={`${routes.patient.root}${routes.patient.directory}`} element={<Directory />} />
          <Route path={`${routes.patient.root}:patientId`} element={<PatientDetails />} />
          <Route path={`${routes.patient.root}${routes.patient.moveReport}`} element={<Directory />} />
          <Route path={`${routes.patient.root}${routes.patient.editReportConditions}`} element={<Directory />} />
          <Route path={`${routes.patient.root}${routes.patient.mergePatients}`} element={<Directory />} />
          <Route path={`${routes.order.root}${routes.order.new}`} element={<NewPatient />} />
          <Route path={`${routes.order.root}${routes.order.new}/orderDetails`} element={<NewOrder />} />
          <Route path={`${routes.order.root}${routes.order.new}patient/:patientId/orderDetails`} element={<NewExistingPatientOrder context="LAB" />} />
          <Route path={`${routes.order.diagnostic}`} element={<OrderDiagnostic />} />
          <Route path={`${routes.order.diagnostic}:orderId`} element={<OrderDiagnostic />} />
          <Route path={`${routes.labUsers.root}${routes.labUsers.directory}`} element={<LabUsersList />} />
          <Route path={`${routes.physicianGroupUsers.root}${routes.physicianGroupUsers.directory}`} element={<PhysicianGroupUsersDirectory />} />
          <Route path={`${routes.physicianGroupUsers.root}${routes.upsert}:userId`} element={<PhysicianGroupUserUpsert />} />
          <Route path={`${routes.physicianGroupUsers.root}${routes.upsert}`} element={<PhysicianGroupUserUpsert />} />
          <Route path={`${routes.physicianGroups.root}`} element={<PhysicianGroupsDirectory />} />
          <Route path={`${routes.physicianGroups.root}${routes.upsert}`} element={<PhysicianGroupUpsert />} />
          <Route path={`${routes.physicianGroups.root}${routes.upsert}:physicianGroupId`} element={<PhysicianGroupUpsert />} />
          <Route path={`${routes.labUsers.root}${routes.upsert}`} element={<LabUserUpsert />} />
          <Route path={`${routes.labUsers.root}${routes.upsert}:userId`} element={<LabUserUpsert />} />
          <Route path={`${routes.dmeUsers.root}${routes.dmeUsers.directory}`} element={<DmeUserDirectory />} />
          <Route path={`${routes.dmeUsers.root}${routes.upsert}`} element={<DmeUserUpsert />} />
          <Route path={`${routes.dmeUsers.root}${routes.upsert}:userId`} element={<DmeUserUpsert />} />
          <Route path={`${routes.reports.root}${routes.reports.stats}`} element={<Stats />} />
          <Route path={`${routes.reports.root}${routes.reports.analytics}`} element={<Analytics />} />
          <Route path={`${routes.bulletins.root}${routes.bulletins.directory}`} element={<BulletinDirectory />} />
          <Route path={`${routes.bulletins.root}${routes.upsert}`} element={<BulletinUpsert />} />
          <Route path={`${routes.bulletins.root}${routes.upsert}:bulletinId`} element={<BulletinUpsert />} />
        </Routes>
      </div>
      <Footer />
    </div>
  )
}

export default DashboardLab
