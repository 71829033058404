/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import { skipToken } from '@reduxjs/toolkit/query/react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import _, { startCase } from 'lodash'
import { Grid } from '@mui/material'
import Button from 'react-bootstrap/Button'

import DashboardContent from '../atoms/DashboardContent'
import EditOrderView from './EditOrder.view'
import { confirm } from 'components/molecules/Confirmation'
import { useAppSelector, useAppDispatch } from 'hooks/redux'
import { insertOrder } from 'store/slice/orderSlice'
import { handleChange, handleSubmit } from '../../atoms/Upsert'
import routes from '../constants/routes'
import * as generalHelper from 'helpers/generalHelper'
import MsgsNotes from './views/MsgsNotes'
import createQueryString from '../../customHooks/useQueryString'
import TestConditionWidget from './views/header/TestConditionWidget'
import { useGetOrderQuery, useRemoveDeviceMutation } from 'store/services/dmeOrder'
import { HstDeviceKind } from 'store/types'
import {
  useSendDmeMessageMutation,
  useGetDmeMessagesQuery,
  useUpdateMessageReadStatusMutation,
} from 'store/services/messages'
import {
  useSendDmeNoteMutation,
  useGetDmeNotesQuery,
  usePinNoteMutation,
} from 'store/services/notes'
import { devices } from 'components/reader/devices'
import { isUnderVerification, isVerificationDone } from 'helpers/orderStateHelpers'
import { switchOffice, setCurrentOfficeTests } from 'store/slice/initSlice'
import { useLazyGetTestTypesQuery } from 'store/services/testTypes'

const deviceLookup = _.keyBy(devices, 'type')

const OrderEdit = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { orderId, tab } = useParams()
  const [redirect, setRedirect] = useState()
  const [sideTab, setSideTab] = useState('notes')
  const init = useAppSelector((state) => state.init)
  const {
    data: order,
    isLoading,
    refetch: refetchOrder,
    error,
  } = useGetOrderQuery(Number(orderId), {
    refetchOnFocus: true,
  })
  const [values, setValues] = useState({})
  const [deviceModalOpen, setDeviceModalOpen] = useState(false)
  const [nightOwlModalOpen, setNightOwlModalOpen] = useState(false)
  const [oneShipModalOpen, setOneShipModalOpen] = useState(false)
  const [hstDeviceModalOpen, setHstDeviceModalOpen] = useState(false)
  const [sendDmeMessage] = useSendDmeMessageMutation()
  const [sendDmeNote] = useSendDmeNoteMutation()
  const [updateMessageReadStatus] = useUpdateMessageReadStatusMutation()
  const [removeDevice] = useRemoveDeviceMutation()
  const [getTestTypes] = useLazyGetTestTypesQuery()
  const [pinNote] = usePinNoteMutation()
  const { data: messages } = useGetDmeMessagesQuery(orderId ? { orderId } : skipToken, {
    refetchOnFocus: true,
  })
  const { data: notes, refetch: refetchNotes } = useGetDmeNotesQuery(orderId ? { orderId } : skipToken, {
    refetchOnFocus: true,
  })
  const hasDeviceSessions = order?.sessions?.sessions?.length
  const currentOfficeId = init.currentOfficeId
  const currentOffice = init.offices.find((o) => Number(o.id) === Number(currentOfficeId))
  const oneShipSupported = currentOffice?.oneShipSupported
  const deviceOffice = init.offices.find((o) => Number(o.id) === Number(order?.device?.officeId))
  const isOneShip = order?.thirdPartyDevice?.deviceType === 'TESTSMARTER'
  const canRemoveDevice = !isUnderVerification(order) && !isVerificationDone(order)

  // console.log('>>>>>>>>>>>>>>>>>>>>> ', error)

  useEffect(() => {
    if (order && Number(order?.officeId) !== Number(currentOfficeId)) {
      confirm('', {
        title: '',
        description: 'Order belongs to another office. Do you want to switch to this office?',
        yesAction: 'Yes, switch office',
      })
        .then(() => {
          sessionStorage.removeItem('ts-dme-menu')
          dispatch(switchOffice(order?.officeId))
          getTestTypes({ officeId: order?.officeId }).then((testTypes) => {
            dispatch(setCurrentOfficeTests(testTypes.data))
          })
        })
        .catch(() => {})
    }
  }, [order])

  useEffect(() => {
    refetchNotes()
  }, [orderId]);

  const addMessage = async (message: string) => {
    if (!orderId) return
    await sendDmeMessage({ orderId: Number(orderId), text: message, context: '' })
  }
  const addNote = async (note: string) => {
    if (!orderId) return
    await sendDmeNote({ orderId: Number(orderId), text: note })
  }

  const isFetching = isLoading // temporary patch

  const deviceCategoryFormat = (type: 'POX' | 'COX' | 'HST') => {
    return type === 'POX'
      ? 'Pulse-Ox Order'
      : type === 'COX'
        ? 'Capnography Order'
        : type === 'HST'
          ? 'HST Order'
          : type === 'PSG'
            ? 'In-Lab Sleep Testing Order'
            : ''
  }

  // validates that we have all data necessary to render UI component
  const patient = order?.patient
  const title = deviceCategoryFormat(order?.testType)

  const subtitle = patient
    ? `${order?.patient?.lastName}${
        order?.patient?.suffix ? ' ' + order?.patient?.suffix : ''
      }, ${order?.patient?.firstName} ${
        order?.patient?.middleName ? _.capitalize(order?.patient?.middleName) : ''
      }`
    : `Order ${orderId}`
  const subtitleExtra = patient?.dob
    ? `${generalHelper.t('Date Of Birth')}: ${moment(order?.patient.dob).format('MM/DD/YYYY')}`
    : ''

  if (error?.status === 403) {
    return (
      <DashboardContent
        className="bg-primary"
        title="Access Denied"
        subtitle={subtitle}
        icon="fal fa-folder-open"
        order={order}
        getOrderParams={{ orderId }}
        content={
          <div className="card card-primary">
            <div className="card-body row">
              <div className="col-12">
                <div className="alert alert-danger" role="alert">
                  You do not have access to this order.
                </div>
              </div>
            </div>
          </div>
        }
      />
    )
  }
  if (order && Number(order?.officeId) !== Number(currentOfficeId)) {
    return (
      <DashboardContent
        className="bg-primary"
        title="Order belongs to another office"
        subtitle={subtitle}
        icon="fal fa-folder-open"
        getOrderParams={{ orderId }}
        content={
          <div className="card card-primary">
            <div className="card-body row">
              <div className="col-12">
                <div className="alert alert-warning" role="alert">
                  This order belongs to another office. Please use the office chooser to switch to
                  this office first.
                </div>
              </div>
            </div>
          </div>
        }
      />
    )
  }

  return (
    <>
      {redirect && <Navigate to={redirect} />}
      <DashboardContent
        className="bg-primary"
        title={`${title} ${order?.cancelled ? '(Cancelled)' : ''}`}
        isFetching={isFetching}
        subtitle={subtitle}
        orderInfo={[
          <Grid
            container
            direction="column"
            sx={{ background: 'white', padding: '5px 10px', position: 'relative' }}
            rowGap="5px">
            <div className="p-1 d-flex flex-column" css={{ '& label': { minWidth: 70 } }}>
              <div>
                <label className="text-bold">Physician: </label>
                <span>
                  {order?.physician ? (
                    <>
                      {order?.physician?.firstName} {order?.physician?.lastName}{' '}
                      {order?.physician?.credential}
                    </>
                  ) : (
                    'Not Assigned'
                  )}
                </span>
              </div>
              <div>
                {order?.testType !== 'PSG' && (
                  <>
                    <label className="text-bold">Device: </label>
                    <span>
                      {order?.device && !isOneShip && (
                        <>
                          {order?.device?.model &&
                            `${deviceLookup[order?.device?.model]?.name ?? 'Unknown type'} - `}
                          {order?.device?.label ?? order?.device?.serial}{' '}
                          {!hasDeviceSessions && (
                            <Button
                              size="sm"
                              variant="outline-primary"
                              className="ml-2"
                              css={{ paddingTop: 1, paddingBottom: 1 }}
                              onClick={() => setDeviceModalOpen(true)}>
                              Edit
                            </Button>
                          )}
                        </>
                      )}
                      {order?.thirdPartyDevice?.deviceType && (
                        <>
                          {order?.thirdPartyDevice?.deviceType === HstDeviceKind.NIGHT_OWL &&
                            'NightOwl '}
                          {isOneShip && 'TestSmarter Device '}
                          {order?.thirdPartyDevice?.deviceType === HstDeviceKind.DREAM_CLEAR &&
                            'Dreamclear '}
                          {order?.thirdPartyDevice?.deviceType === HstDeviceKind.HST &&
                            'Home Sleep Device '}
                          {!hasDeviceSessions && tab !== 'hstDevice' && (
                            <Button
                              size="sm"
                              variant="outline-primary"
                              className="ml-2"
                              css={{ paddingTop: 1, paddingBottom: 1 }}
                              onClick={() => {
                                if (order?.testType === 'HST') {
                                  setHstDeviceModalOpen(true)
                                } else {
                                  setOneShipModalOpen(true)
                                }
                              }}>
                              Edit
                            </Button>
                          )}
                        </>
                      )}
                      {!order?.device && !order?.thirdPartyDevice && 'Not Assigned'}
                    </span>
                  </>
                )}
                {(order?.device || order?.thirdPartyDevice) &&
                  !hasDeviceSessions &&
                  order?.thirdPartyDevice?.serial !== order?.device?.serial &&
                  (!isOneShip || canRemoveDevice) &&
                  order?.testType !== 'HST' && (
                    <i
                      className="fa fa-trash"
                      css={{ marginLeft: 10, cursor: 'pointer', transform: 'translateY(1px)' }}
                      onClick={() =>
                        confirm('Device')
                          .then(async () => {
                            await removeDevice({ orderId: Number(orderId) })
                            navigate(`${routes.index}${routes.order.edit}${order.id}/summary`)
                          })
                          .catch(() => {})
                      }
                    />
                  )}
                {order?.device &&
                  Number(order?.officeId) !== Number(order?.device.officeId) &&
                  !isOneShip && (
                    <>
                      <br />
                      <span className="text-warning">
                        (Device is from {deviceOffice?.officeName} - {deviceOffice?.addressCity},{' '}
                        {deviceOffice?.addressState})
                      </span>
                    </>
                  )}
              </div>
            </div>
          </Grid>,
          <Grid
            container
            direction="column"
            sx={{ background: 'white', padding: '5px 10px', position: 'relative' }}
            rowGap="5px">
            <div className="p-1 d-flex flex-column" css={{ '& label': { minWidth: 90 } }}>
              <div>
                <label className="text-bold">Status: </label>
                <span>{startCase(order?.bucket?.toLowerCase())}</span>
              </div>
              <div>
                <label className="text-bold">Verification: </label>
                <span>
                  {order?.state?.VERIFICATION?.RESULT
                    ? 'In Progress'
                    : startCase(order?.state?.VERIFICATION?.toLowerCase())}
                </span>
              </div>
            </div>
          </Grid>,
          ...(order?.testType !== 'PSG'
            ? [
                <Grid
                  container
                  direction="column"
                  sx={{ background: 'white', padding: '5px 10px', position: 'relative' }}
                  rowGap="5px">
                  <TestConditionWidget order={order} />
                </Grid>,
              ]
            : []),
        ]}
        subtitleExtra={subtitleExtra}
        icon="fal fa-folder-open"
        order={order}
        getOrderParams={{ orderId }}
        content={
          <EditOrderView
            order={order}
            notes={notes}
            deviceModalOpen={deviceModalOpen}
            setDeviceModalOpen={setDeviceModalOpen}
            nightOwlModalOpen={nightOwlModalOpen}
            setNightOwlModalOpen={setNightOwlModalOpen}
            oneShipModalOpen={oneShipModalOpen}
            setOneShipModalOpen={setOneShipModalOpen}
            hstDeviceModalOpen={hstDeviceModalOpen}
            setHstDeviceModalOpen={setHstDeviceModalOpen}
            refetchOrder={refetchOrder}
            oneShipSupported={oneShipSupported}
            values={values}
            setValues={setValues}
            getOrderParams={{ orderId }}
            handleChange={(event) => {
              handleChange(event, values, setValues)
            }}
            handleTabChange={() => {}}
            handleSubmit={(event) => {
              // Invalidate order list queries and reload view
              handleSubmit(event, {
                values: values,
                functions: {
                  insert: insertOrder,
                },
                callback: {
                  function: setRedirect,
                  params: '/order',
                },
                dispatch,
              })
            }}
            sideTab={sideTab}
            setSideTab={setSideTab}
          />
        }
        extra={
          <MsgsNotes
            messages={messages ?? []}
            addMessage={addMessage}
            notes={notes ?? []}
            addNote={addNote}
            sideTab={sideTab}
            setSideTab={setSideTab}
            updateMessageReadStatus={updateMessageReadStatus}
            pinNote={pinNote}
            orderId={orderId}
          />
        }
        breadcrumb={[
          { label: 'Home', to: `${routes.index}` },
          {
            label: title,
            to: `${routes.index}${routes.order.rootHub}${
              order?.testType === 'POX'
                ? `?${createQueryString(routes.order.pulseOx.open)}`
                : order?.testType === 'COX'
                  ? `?${createQueryString(routes.order.capno.open)}`
                  : `?${createQueryString(routes.order.hst.open)}`
            }`,
          },
          {
            label: `#${orderId} - ${patient ? patient.firstName : ''} ${
              patient ? patient.lastName : ''
            }`,
          },
        ]}
      />
    </>
  )
}

export default OrderEdit
