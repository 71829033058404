/** @jsxImportSource @emotion/react */
import { useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import ReactSelect from 'react-select';

import { useCreateLabDeviceMutation, useGetDeviceBrandsQuery } from 'store/services/devices';
import DataTable from 'components/atoms/DataTable';
import { TableWrapper } from 'components/atoms/TableWrapper';
import DashboardContent from '../atoms/DashboardContent';
import Input from '../../atoms/Input';
import { PopoverWrapper } from 'components/atoms/PopoverWrapper';
import { keyBy } from 'lodash';
import Button from "../../atoms/Button";
import { DeviceStates } from '../device/constants';
import Toast from "../../atoms/Toast";

type ColumnIndexes = {
  label: number;
  mac: number;
  serial: number;
};

type DeviceImportList = {
  currentPage: number;
  items: NewDevice[];
  totalItems: number;
};

type DeviceRow = {
  device: NewDevice;
};

type NewDevice = {
  bluetoothId: string;
  brandId: number | null;
  enabled: boolean;
  importState: {
    imported: boolean;
    error: boolean;
    errorText: string | null;
  };
  label: string;
  mac: string;
  officeId: null;
  notes: string;
  purchaseCost: number | null;
  salePrice: number | null;
  serial: string;
  state: string;
  statusUpdatedAt: Date;
};

type TableDataEntry = {
  row: {
    index: number;
    values: NewDevice;
  };
};

const DeviceImport = () => {
  const { data: brands } = useGetDeviceBrandsQuery({ userType: 'lab' });
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [createDevice] = useCreateLabDeviceMutation()

  const brandsLookup = useMemo(() => {
    if (!brands?.items) return {};
    return keyBy(brands.items, 'model');
  }, [brands]);

  const brandsOptions = useMemo(
    () => brands?.items?.map((brand) => ({ value: brand.id, label: brand.name })) ?? [],
    [brands],
  );

  const [deviceData, setDeviceData] = useState<DeviceImportList>({
    currentPage: 0,
    items: [],
    totalItems: 0
  });

  const fileInputRef = useRef<HTMLInputElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const openFileDialog = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // Parses the input file.
  // The file is expected to be a (.csv) containing comma-separated values.
  // The first row of the file must contain the column headers. There are three
  // required columns: "Label", "MAC Address", and "Serial Number". These columns
  // can be in any order, so long as they are correctly labeled in the first row.
  const parseImportFile = (fileContents: string) => {
    const dequote = (value: string): string => {
      if (value.startsWith('"') && value.endsWith('"')) {
        return value.slice(1, -1).replace(/"{2}/, '"');
      }
      return value;
    };
    const csv: string[][] = fileContents
        .replace(/((\r\n)+|\r+|\n+)/g, '\n')
        .split('\n')
        .filter((line: string): boolean => {
          if (line) {
            return Boolean(String(line).trim().length > 0);
          }
          return false;
        })
        .map((line: string): string[] => {
          return line
              .split(',')
              .map((value: string): string => dequote(String(value).trim()));
        });
    if (csv.length < 2) {
      throw new Error('Invalid file format, or file contains no data');
    }
    const columnIndex = ((columns: string[]) => {
      let label: number | undefined;
      let mac: number | undefined;
      let serial: number | undefined;
      columns.forEach((column: string, index: number): void => {
        column = String(column).toLowerCase().replace(/[^a-z]/g, '');
        if (column.includes('label')) {
          label = index;
        } else if (column.includes('serial')) {
          serial = index;
        } else if (column.includes('mac')) {
          mac = index;
        }
      });
      if (label === undefined) {
        throw new Error('Could not locate the "Label" column. Please ensure that the first row in the spreadsheet contains the column names, and that the label column is properly labeled "Label".');
      }
      if (mac === undefined) {
        throw new Error('Could not locate the "MAC Address" column. Please ensure that the first row in the spreadsheet contains the column names, and that the MAC address is properly labeled "MAC Address".');
      }
      if (serial === undefined) {
        throw new Error('Could not locate the "Serial Number" column. Please ensure that the first row in the spreadsheet contains the column names, and that the serial number column is properly labeled "Serial number".');
      }
      return {label, mac, serial};
    })(csv.shift()!);
    const now = new Date();
    const formatLabel = (input: string): string => {
      return String(input).trim().toUpperCase();
    };
    const formatMAC = (input: string): string => {
      const value = String(input).trim().toUpperCase().replace(/[^A-F0-9]/g, '');
      if (value.match(/^[A-F0-9]{12}$/)) {
        return value.match(/.{2}/g)!.join(':');
      }
      return '';
    };
    const formatSerialNumber = (input: string): string => {
      const value = String(input).trim().toUpperCase();
      return (value.match(/^[A-Z]{4}[0-9]{6}$/)) ? value : '';
    };
    const deviceArray: NewDevice[] = [];
    csv.forEach((row: string[], index: number) => {
      const label = formatLabel(row[columnIndex.label]);
      const mac = formatMAC(row[columnIndex.mac]);
      const serial = formatSerialNumber(row[columnIndex.serial]);
      deviceArray.push({
        bluetoothId: mac,
        brandId: brandsLookup['tsot'].id,
        enabled: true,
        importState: {
          imported: false,
          error: false,
          errorText: null,
        },
        label,
        mac,
        officeId: null,
        notes: '',
        purchaseCost: null,
        salePrice: null,
        serial,
        state: DeviceStates.STOCK,
        statusUpdatedAt: now,
      })
    });
    setDeviceData({
      items: deviceArray,
      currentPage: 0,
      totalItems: deviceArray.length,
    });
  };

  // Helper function that takes a chosen file, reads the file as text,
  // and calls the import file parser.
  const loadImportFile = (event: any) => {
    try {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        const fileContents = fileReader.result;
        if (!fileContents) {
          throw new Error('File is not readable');
        }
        if (typeof fileContents !== 'string') {
          throw new Error('Incorrect file format');
        }
        parseImportFile(fileContents);
      };
      fileReader.readAsText(event.target.files[0])
    } catch (error) {
      console.log(error);
      const message = (error instanceof Error) ? error.message: 'Unknown error';
      alert(`Error: ${message}`);
    }
  };

  // Helper function that takes all device rows and flips the "Bluetooth ID"
  // value. This is used for importing newer TestSmarter OneTrips where the
  // "Bluetooth ID" is the MAC address with the octets in reverse order.
  const flipBluetoothIDs = () => {
    const devices = deviceData.items.map((device: NewDevice): NewDevice => {
      device.bluetoothId = device.bluetoothId.split(':').reverse().join(':');
      return device;
    });
    setDeviceData({ ...deviceData, items: devices });
  };

  const postDevices = async () => {
    let submitButtonValue = 'Begin Import';
    if (submitButtonRef.current) {
      submitButtonValue = submitButtonRef.current.innerHTML;
      submitButtonRef.current.innerHTML = 'Importing, please wait...';
      submitButtonRef.current.disabled = true;
    }
    const devices: NewDevice[] = deviceData.items;
    for (const device of devices) {
      const { importState, ...data } = device;
      try {
        await createDevice(data).unwrap();
        device.importState.imported = true;
        device.importState.error = false;
        device.importState.errorText = null;
        // Toast({type: 'success', label: `Device imported`});
      } catch (error) {
        let message = 'Unknown error';
        if ('status' in error && error.status === 400) {
          if ('data' in error && error.data) {
            if ('message' in error.data && error.data.message) {
              message = error.data.message;
            }
          }
        }
        device.importState.imported = false;
        device.importState.error = true;
        device.importState.errorText = message;
        // Toast({ type: 'error', label: `Could not import device`});
      }
    }
    if (submitButtonRef.current) {
      submitButtonRef.current.innerHTML = submitButtonValue;
      submitButtonRef.current.disabled = false;
    }
  };

  const header = [
    {
      accessor: 'brandId',
      Header: 'Type',
      Cell: ({ row }: TableDataEntry) => {
        const updateDevice = (index: number, brandId?: number) => {
          const deviceArray = deviceData.items;
          deviceArray[index].brandId = brandId ?? null;
          setDeviceData({ ...deviceData, items: deviceArray });
        };
        return (
            <ReactSelect
                className="basic-single w-100 mr-3"
                css={{ '& .select__control': { height: 34, minHeight: 'unset' } }}
                classNamePrefix="select"
                isClearable={false}
                placeholder="Select Type"
                name="type"
                onChange={(item) => updateDevice(row.index, item?.value)}
                options={brandsOptions}
                value={brandsOptions?.find((brand) => brand.value === row.values.brandId)}
            />
        )
      }
    },
    {
      accessor: 'label',
      Header: 'Label',
      Cell: ({ row }: TableDataEntry) => (
          <Input id={`label-${row.index}`} name="label" value={row.values.label} />
      ),
    },
    {
      accessor: 'serial',
      Header: 'Serial No.',
      Cell: ({ row }: TableDataEntry) => (
          <Input id={`serial-${row.index}`} name="serial" value={row.values.serial} />
      ),
    },
    {
      accessor: 'mac',
      Header: 'MAC Addr.',
      Cell: ({ row }: TableDataEntry) => (
          <Input id={`mac-${row.index}`} name="mac" value={row.values.mac} />
      ),
    },
    {
      accessor: 'bluetoothId',
      Header: 'Bluetooth ID',
      Cell: ({ row }: TableDataEntry) => (
          <Input id={`bluetooth_id-${row.index}`} name="bluetooth_id" value={row.values.bluetoothId} />
      ),
    },
    {
      accessor: 'importState',
      Header: 'Status',
      Cell: ({ row }: TableDataEntry) => {
        if (row.values.importState.imported) {
          return (<span css={{color: 'forestgreen', fontWeight: 'bold'}}>Imported</span>);
        } else if (row.values.importState.error) {
          return (
              <PopoverWrapper
                  placement="left"
                  customHeader="Notes"
                  customContent={<div className="p-2">{row.values.importState.errorText ?? 'Unspecified error'}</div>}>
                <span css={{color: '#c22', fontWeight: 'bold'}}>ERROR</span>
              </PopoverWrapper>
          );
        } else {
          return (<span css={{color: '#aaa', fontStyle: 'italic'}}>Pending</span>);
        }
      },
    },
  ];

  const StyledTableWrapper = styled(TableWrapper)`
    th {
      padding: 0.6rem;
    }
    td {
      vertical-align: middle;
      padding: 0.6rem;
      div {
        span {
          font-size: 0.9rem;
        }
      }
    }
  `;

  return (
    <DashboardContent
      title="Devices"
      icon="fa fa-hdd"
      content={
        <div className="card card-primary" css={{ overflow: 'visible', overflowY: 'visible', overflowX: 'visible' }}>
          <input
              css={{ display: 'block', height: '0', position: 'absolute', visibility: 'hidden', width: '0' }}
              onChange={loadImportFile}
              ref={fileInputRef}
              type="file"
          />
          <div css={{ padding: '15px' }}>
            <Button
                className="btn-primary"
                onClick={openFileDialog}
                label="Choose a file"
            />
            <Button
                className="btn-secondary"
                css={{ marginLeft: '15px' }}
                onClick={flipBluetoothIDs}
                label="Flip Bluetooth IDs"
            />
            <Button
                className="btn-secondary"
                css={{ marginLeft: '15px' }}
                onClick={postDevices}
                label="Begin Import"
                ref={submitButtonRef}
            />
          </div>
          <form onSubmit={() => {}}>
            <StyledTableWrapper
              className="card-body row"
              css={{
                '& [role=row]:hover': {
                  '& .open-new-tab': {
                    display: 'block',
                  },
                },
              }}>
              <DataTable
                data={deviceData}
                entityName="devices"
                onRowClick={(row) => {
                  //navigate(`${routes.index}${routes.device.root}${routes.upsert}${row.original.id}`)
                }}
                pageSize={pageSize}
                searchable={false}
                setCurrentPage={setCurrentPage}
                setPageSize={setPageSize}
                sortable={false}
                status={false}
                tableDataEmptyText='No devices imported. Choose a file to begin.'
                tableDataErrorText='Error reading file'
                tableColumns={header}
                tableKey={'lab_device_import'}
              />
            </StyledTableWrapper>
          </form>
        </div>
      }
      breadcrumb={[{ label: 'Device Import' }]}
    />
  )
}

export default DeviceImport
