export const phoneNumberMask = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

export const dobMask = [/[0-9]/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]

export const ssnMask = [/[0-9]/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]

export const zipMask = [/[0-9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]

export const macMask = [
  /[0-9A-Fa-f]/,
  /[0-9A-Fa-f]/,
  ':',
  /[0-9A-Fa-f]/,
  /[0-9A-Fa-f]/,
  ':',
  /[0-9A-Fa-f]/,
  /[0-9A-Fa-f]/,
  ':',
  /[0-9A-Fa-f]/,
  /[0-9A-Fa-f]/,
  ':',
  /[0-9A-Fa-f]/,
  /[0-9A-Fa-f]/,
  ':',
  /[0-9A-Fa-f]/,
  /[0-9A-Fa-f]/,
]
