/** @jsxImportSource @emotion/react */
import { useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import moment from 'moment'
import ReactSelect from 'react-select'
import { pick, startCase } from 'lodash'

import { useGetDeviceBrandsQuery, useGetLabDevicesQuery } from 'store/services/devices'
import DataTable from 'components/atoms/DataTable'
import { TableWrapper } from 'components/atoms/TableWrapper'
import DashboardContent from '../atoms/DashboardContent'
import { handleChange } from '../../atoms/Upsert'
import ButtonNew from '../../atoms/ButtonNew'
import Input from '../../atoms/Input'
import Link from '../../atoms/Link'
import routes from '../constants/routes'
import { PopoverWrapper } from 'components/atoms/PopoverWrapper'
import { keyBy } from 'lodash'
import { useGetLabOfficesQuery } from 'store/services/office'
import Select from 'components/atoms/Select'
import { statusOptions as statusOptionsRaw } from './constants'

const statusOptions = statusOptionsRaw.map((i) => ({ ...i, label: i.key }))

const StyledTableWrapper = styled(TableWrapper)`
  th {
    padding: 0 0.2rem 0.4rem 0.1rem;
  }
  td {
    vertical-align: middle;
    padding: 0.6rem;
    div {
      span {
        font-size: 0.9rem;
      }
    }
  }
`

const filterOptions = [
  { value: 'type', label: 'Type' },
  { value: 'label', label: 'Label' },
  { value: 'serialBegins', label: 'Serial No. begins with...' },
  { value: 'serialEnds', label: 'Serial No. ends with...' },
  { value: 'mac', label: 'MAC Address' },
  { value: 'state', label: 'Status' },
  { value: 'statusUpdatedAt', label: 'Status Date' },
  { value: 'dme', label: 'Assigned DME' },
  { value: 'owningDme', label: 'Owning DME' },
  { value: 'notes', label: 'Notes' },
  // { value: 'createdAt', label: 'Date Added' },
  // { value: 'updated', label: 'Date Updated' },
]

const Devices = () => {
  const navigate = useNavigate()
  const { data: brands } = useGetDeviceBrandsQuery({ userType: 'lab' })
  const { data: offices } = useGetLabOfficesQuery({})
  const [pageSize, setPageSize] = useState(20)
  const [currentPage, setCurrentPage] = useState(0)
  const [sortOptions, setSortOptions] = useState({})
  const [values, setValues] = useState({
    column: '',
    search: '',
    brandId: '',
    officeId: '',
    ownedByOfficeId: '',
  })

  const { data: devices, isLoading } = useGetLabDevicesQuery({
    ...pick(values, ['brandId', 'officeId', 'ownedByOfficeId']),
    [values.column]: values.search,
    page: currentPage,
    size: pageSize,
    sortField: sortOptions?.id,
    sortOrder: sortOptions?.desc ? 'desc' : 'asc',
  })

  const brandsLookup = useMemo(() => {
    if (!brands?.items) return {}
    return keyBy(brands.items, 'id')
  }, [brands])

  const brandsOptions = useMemo(
    () => brands?.items?.map((brand) => ({ value: brand.id, label: brand.name })) ?? [],
    [brands],
  )

  const officesOptions = useMemo(
    () =>
      offices?.map((o) => ({
        value: o.id,
        label: `${o.officeName} (${o.addressCity}, ${o.addressState})`,
      })),
    [offices],
  )

  const deviceList = useMemo(() => {
    if (!devices?.items) return devices

    return {
      ...devices,
      items: devices.items.map((device) => ({
        ...brandsLookup[device.brandId],
        ...device,
        updatedAt: device.updatedAt?.slice(0, 10),
      })),
    }
  }, [devices, brandsLookup])

  const header = [
    {
      accessor: 'id',
      to: `${routes.index}${routes.device.root}${routes.upsert}`,
      Header: 'ID',
    },
    {
      accessor: 'name',
      Header: 'Type',
      Cell: ({ row }) => (
        <div css={{ position: 'relative' }}>
          <div className="device-type">
            {row.original.name}
            <Link
              className="open-new-tab"
              css={{ display: 'none', position: 'absolute', right: '0', top: 0 }}
              to={`${routes.index}${routes.device.root}${routes.upsert}${row.original.id}`}
              target="_blank"
              icon="fas fa-external-link-alt"
              onClick={(e) => e.stopPropagation()}
            />
          </div>
        </div>
      ),
    },
    { accessor: 'serial', key: 'serial', Header: 'Serial' },
    { accessor: 'label', Header: 'Label' },
    { accessor: 'mac', Header: 'MAC Addr.' },
    {
      accessor: 'state',
      key2: 'name',
      Header: 'Status',
      Cell: ({ row }) => startCase(row.original?.state?.toLowerCase().replace(/_/g, ' ')),
    },
    {
      accessor: 'statusUpdatedAt',
      Header: 'Status Date',
      Cell: ({ row }) => {
        if (!row.original.statusUpdatedAt) return null
        return moment(row.original.statusUpdatedAt).format('MM/DD/YYYY')
      },
    },
    { accessor: 'officeName', Header: 'DME' },
    {
      accessor: 'belongsToDme',
      Header: 'DME Owned',
      Cell: ({ row }) => (row.original.belongsToDme ? 'Yes' : '-'),
    },
    {
      accessor: 'notes',
      Header: 'Notes',
      Cell: ({ row }) =>
        row.original.notes && (
          <PopoverWrapper
            placement="left"
            customHeader="Notes"
            customContent={<div className="p-2">{row.original.notes}</div>}>
            <span>
              <i className="fa fa-sticky-note" css={{ color: 'rgb(254,200,0)' }} />
            </span>
          </PopoverWrapper>
        ),
    },
    {
      accessor: 'createdAt',
      Header: 'Date Added',
      Cell: ({ row }) => moment(row.original.createdAt).format('MM/DD/YYYY'),
    },
    {
      accessor: 'updatedAt',
      Header: 'Last Updated',
      Cell: ({ row }) => moment(row.original.updatedAt).format('MM/DD/YYYY'),
    },
  ]

  return (
    <DashboardContent
      title="Devices"
      icon="fa fa-hdd"
      content={
        <div className="card card-primary">
          <form onSubmit={() => {}}>
            <div className="card-header row" css={{ borderBottom: 'none', position: 'relative', zIndex: '999' }}>
              <div className="col-sm-1">
                <Select
                  name="pageSize"
                  className="basic-single w-100 mr-3 form-control"
                  handleChange={(e) => setPageSize(Number(e.target.value))}
                  options={['20', '50', '100'].map((i) => ({ value: i, label: i }))}
                  value={pageSize}
                />
              </div>
              <div className="col mr-1">
                <ReactSelect
                  className="basic-single w-100 mr-3"
                  css={{ '& .select__control': { height: 34, minHeight: 'unset' } }}
                  classNamePrefix="select"
                  isClearable
                  placeholder="Select Column"
                  name="hasPayerId"
                  onChange={(item) =>
                    setValues({
                      ...values,
                      column: item?.value ?? '',
                      ...(!item?.value ? { brandId: '', officeId: '', ownedByOfficeId: '' } : {}),
                      search: '',
                    })
                  }
                  options={filterOptions}
                  value={filterOptions.find((o) => o.value === values.column)}
                />
              </div>

              {values.column === 'type' && (
                <div className="col input-group mb-3">
                  <ReactSelect
                    className="basic-single w-100 mr-3"
                    css={{ '& .select__control': { height: 34, minHeight: 'unset' } }}
                    classNamePrefix="select"
                    isClearable
                    placeholder="Select Type"
                    name="type"
                    onChange={(item) => setValues({ ...values, brandId: item?.value ?? '' })}
                    options={brandsOptions}
                    value={brandsOptions?.find((brand) => brand.value === values.brandId)}
                  />
                </div>
              )}
              {(values.column === 'dme' || values.column === 'owningDme') && (
                <div className="col input-group mb-3">
                  <ReactSelect
                    className="basic-single w-100 mr-3"
                    css={{ '& .select__control': { height: 34, minHeight: 'unset' } }}
                    classNamePrefix="select"
                    isClearable
                    isSearchable
                    placeholder="Select DME Office"
                    name={values.column === 'dme' ? 'officeId' : 'ownedByOfficeId'}
                    onChange={(item) =>
                      setValues({
                        ...values,
                        [values.column === 'dme' ? 'officeId' : 'ownedByOfficeId']:
                          item?.value ?? '',
                      })
                    }
                    options={officesOptions}
                    value={
                      officesOptions?.find(
                        (o) =>
                          o.value ==
                          values[values.column === 'dme' ? 'officeId' : 'ownedByOfficeId'],
                      ) ?? null
                    }
                  />
                </div>
              )}
              {values.column === 'state' && (
                <div className="col input-group mb-3">
                  <ReactSelect
                    className="basic-single w-100 mr-3"
                    css={{ '& .select__control': { height: 34, minHeight: 'unset' } }}
                    classNamePrefix="select"
                    isClearable
                    isSearchable
                    placeholder="Select Status"
                    name="search"
                    onChange={(item) => setValues({ ...values, search: item?.value ?? '' })}
                    options={statusOptions}
                    value={statusOptions?.find((o) => o.value == values.search) ?? null}
                  />
                </div>
              )}
              {!['type', 'dme', 'owningDme', 'state'].includes(values.column) && (
                <div className="col input-group mb-3">
                  <Input
                    autoFocus={true}
                    value={values.search}
                    label={false}
                    placeholder="Search..."
                    name="search"
                    disabled={!values.column}
                    parentDiv={null}
                    handleChange={(event) => {
                      handleChange(event, values, setValues)
                    }}
                  />{' '}
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <i className="fas fa-search" />
                    </span>
                  </div>
                </div>
              )}

              <div className="col text-right">
                <Link
                  to={`${routes.index}${routes.deviceImport.root}`}
                  label="Bulk Import"
                  icon="fa fa-file-import"
                  className="btn btn-default"
                />{' '}
                <ButtonNew to={`${routes.index}${routes.device.root}${routes.upsert}`} />
              </div>
            </div>
            <StyledTableWrapper
              className="card-body row"
              css={{
                '& [role=row]:hover': {
                  '& .open-new-tab': {
                    display: 'block',
                  },
                },
              }}>
              <DataTable
                tableKey={'lab_devices'}
                data={deviceList}
                status={isLoading}
                tableColumns={header}
                pageSize={pageSize}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                setSortOptions={setSortOptions}
                entityName="devices"
                onRowClick={(row) => {
                  navigate(`${routes.index}${routes.device.root}${routes.upsert}${row.original.id}`)
                }}
              />
            </StyledTableWrapper>
          </form>
        </div>
      }
      breadcrumb={[{ label: 'Devices' }]}
    />
  )
}

export default Devices
